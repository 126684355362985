<template>
    <b-row>
        <b-col cols="12">
            <div
                class="d-sm-flex justify-content-between align-items-center mb-4"
            >
                <feather
                    type="arrow-left-circle"
                    v-b-popover.hover.top="'Presione esc'"
                    role="button"
                    class="text-secondary"
                    size="2rem"
                    @click="back"
                ></feather>
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        size="md"
                        class="mr-4"
                        v-b-popover.hover.top="'Presione esc'"
                        @click="back"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        class="d-flex align-items-center"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </div>
        </b-col>
        <b-col
            cols="12"
            lg="12"
            class="d-flex align-items-stretch"
            style="min-height: 70vh"
        >
            <b-card class="mb-4 w-100">
                <div>
                    <b-form class="p-5">
                        <div class="d-flex">
                            <div>
                                <h3 class="mb-2">Datos del item</h3>
                                <div class="text-gray mb-5">
                                    Por favor registre los datos del item para
                                    continuar
                                </div>
                            </div>
                        </div>
                        <b-row>
                            <b-col cols="12">
                                <b-form-group>
                                    <label for="activo">Activo</label>
                                    <b-form-checkbox
                                        id="activo"
                                        size="lg"
                                        switch
                                        v-model="$v.item.activo.$model"
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row v-hotkey="keymap" class="mt-4">
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="referencia">Referencia</label>
                                    <b-form-input
                                        id="referencia"
                                        type="text"
                                        placeholder="Ej: 001"
                                        v-model="$v.item.referencia.$model"
                                        :state="validateState('referencia')"
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.item.referencia.$anyError"
                                    >
                                        ({{ $v.item.referencia.$model.length }}
                                        /
                                        {{
                                            $v.item.referencia.$params.maxLength
                                                .max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.item.referencia.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.item.referencia.$params.maxLength
                                                .max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.item.referencia.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="codigo_barra"
                                        >Código de barras</label
                                    >
                                    <b-form-input
                                        id="codigo_barra"
                                        type="text"
                                        placeholder="Ej: 12345"
                                        v-model="$v.item.codigo_barra.$model"
                                        :state="validateState('codigo_barra')"
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.item.codigo_barra.$anyError"
                                    >
                                        ({{
                                            $v.item.codigo_barra.$model.length
                                        }}
                                        /
                                        {{
                                            $v.item.codigo_barra.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.item.codigo_barra.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.item.codigo_barra.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.item.codigo_barra.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6" lg="6" xl="6">
                                <b-form-group>
                                    <label for="descripcion">Descripción</label>
                                    <b-form-input
                                        id="descripcion"
                                        type="text"
                                        placeholder="Ej: Descripción del item"
                                        v-model="$v.item.descripcion.$model"
                                        :state="validateState('descripcion')"
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.item.descripcion.$anyError"
                                    >
                                        ({{ $v.item.descripcion.$model.length }}
                                        /
                                        {{
                                            $v.item.descripcion.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.item.descripcion.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.item.descripcion.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.item.descripcion.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-4">
                            <b-col cols="12" md="4">
                                <b-form-group>
                                    <label for="unidad_contenido_id"
                                        >Unidad de Contenido
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="unidad_contenido_id"
                                        :items="unidadesContenido"
                                        item-text="descripcion"
                                        item-value="id"
                                        open-on-focus
                                        :disabled="
                                            unidadesContenido.length === 0 ||
                                            read_only
                                        "
                                        v-model="
                                            $v.item.unidad_contenido_id.$model
                                        "
                                        :state="
                                            validateState('unidad_contenido_id')
                                        "
                                    />
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            $v.item.unidad_contenido_id.$error
                                        "
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4">
                                <b-form-group>
                                    <label for="contenido">Contenido</label>
                                    <b-form-input
                                        id="contenido"
                                        type="number"
                                        placeholder="Ej: 10"
                                        v-model="$v.item.contenido.$model"
                                        :state="validateState('contenido')"
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.item.contenido.$anyError"
                                    >
                                        ({{
                                            $v.item.contenido.$model
                                                ? $v.item.contenido.$model.toString()
                                                      .length
                                                : ''.length
                                        }}
                                        /
                                        {{
                                            $v.item.contenido.$params.maxLength
                                                .max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.item.contenido.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.item.contenido.$params.maxLength
                                                .max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.item.contenido.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <!-- <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="unidad_dian_id"
                                        >Unidad de Medida DIAN
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="unidad_dian_id"
                                        :items="unidadesDian"
                                        item-text="descripcion"
                                        item-value="id"
                                        open-on-focus
                                        :disabled="
                                            unidadesDian.length === 0 ||
                                            read_only
                                        "
                                        v-model="$v.item.unidad_dian_id.$model"
                                        :state="validateState('unidad_dian_id')"
                                    />
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="$v.item.unidad_dian_id.$error"
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col> -->
                            <b-col cols="12" md="4">
                                <b-form-group>
                                    <label for="tipo_item"
                                        >Tipo item
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="tipo_item_id"
                                        :items="tiposItem"
                                        item-text="descripcion"
                                        item-value="id"
                                        open-on-focus
                                        :disabled="
                                            tiposItem.length === 0 || read_only
                                        "
                                        v-model="$v.item.tipo_item_id.$model"
                                        :state="validateState('tipo_item_id')"
                                    />
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="$v.item.tipo_item_id.$error"
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-4">
                            <b-col cols="12" md="4" lg="4" xl="4">
                                <b-form-group>
                                    <label for="unidad_embalaje_id"
                                        >Unidad de embalaje
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="unidad_embalaje_id"
                                        :items="unidadesEmbalaje"
                                        item-text="descripcion"
                                        item-value="id"
                                        open-on-focus
                                        :disabled="
                                            unidadesEmbalaje.length === 0 ||
                                            read_only
                                        "
                                        v-model="
                                            $v.item.unidad_embalaje_id.$model
                                        "
                                        :state="
                                            validateState('unidad_embalaje_id')
                                        "
                                    />
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="$v.item.unidad_embalaje_id.$error"
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4" lg="4" xl="4">
                                <label for="precio_base_venta"
                                    >Precio Base de Venta
                                    <small style="color: red">*</small></label
                                >
                                <currencyInput
                                    id="precio_base_venta"
                                    v-model="$v.item.precio_base_venta.$model"
                                    :state="validateState('precio_base_venta')"
                                    :disabled="read_only"
                                />
                                <div
                                    class="length-field mt-1"
                                    v-if="!$v.item.precio_base_venta.$anyError"
                                >
                                    ({{
                                        $v.item.precio_base_venta.$model.toString()
                                            .length
                                    }}
                                    /
                                    {{
                                        $v.item.precio_base_venta.$params
                                            .maxLength.max
                                    }})
                                </div>
                                <div
                                    class="invalid-field mt-1"
                                    v-if="!$v.item.precio_base_venta.maxLength"
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.item.precio_base_venta.$params
                                            .maxLength.max
                                    }}
                                    caracteres
                                </div>
                                <div
                                    class="invalid-field mt-1"
                                    v-if="!$v.item.precio_base_venta.required"
                                >
                                    Campo requerido
                                </div>
                            </b-col>
                            <b-col cols="12" md="4" lg="4" xl="4">
                                <b-form-group>
                                    <label for="subcategoria_id"
                                        >Subcategoría
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="subcategoria_id"
                                        :items="subcategorias"
                                        item-text="descripcion"
                                        item-value="id"
                                        open-on-focus
                                        show-create-button
                                        :create-method="
                                            () => {
                                                $bvModal.show(
                                                    'create-subcategoria-modal'
                                                )
                                            }
                                        "
                                        :disabled="
                                            subcategorias.length === 0 ||
                                            read_only
                                        "
                                        v-model="$v.item.subcategoria_id.$model"
                                        :state="
                                            validateState('subcategoria_id')
                                        "
                                    />
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="$v.item.subcategoria_id.$error"
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-4">
                            <b-col
                                cols="12"
                                :md="item.tipo_ipoconsumo === 'NA' ? 12 : 6"
                                :lg="item.tipo_ipoconsumo === 'NA' ? 12 : 6"
                                :xl="item.tipo_ipoconsumo === 'NA' ? 12 : 6"
                            >
                                <b-form-group>
                                    <label for="tipo_ipoconsumo"
                                        >Tipo de impoconsumo
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <!-- {{ $v.item.tipo_ipoconsumo.$model }} -->
                                    <jautocomplete
                                        id="tipo_ipoconsumo"
                                        :items="tiposIpoconsumo"
                                        item-text="descripcion"
                                        item-value="id"
                                        open-on-focus
                                        v-model="$v.item.tipo_ipoconsumo.$model"
                                        :state="
                                            validateState('tipo_ipoconsumo')
                                        "
                                        :disabled="read_only"
                                    />

                                    <!-- <b-form-select
                                        id="tipo_ipoconsumo"
                                        :options="tiposIpoconsumo"
                                        text-field="descripcion"
                                        value-field="id"
                                        v-model="$v.item.tipo_ipoconsumo.$model"
                                        :state="
                                            validateState('tipo_ipoconsumo')
                                        "
                                        :disabled="read_only"
                                    >
                                    </b-form-select> -->
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="$v.item.tipo_ipoconsumo.$error"
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6" lg="6" xl="6">
                                <b-form-group v-if="item.tipo_ipoconsumo == 1">
                                    <label for="valor_ipoconsumo"
                                        >Valor impoconsumo
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <currencyInput
                                        id="valor_ipoconsumo"
                                        v-model="
                                            $v.item.valor_ipoconsumo.$model
                                        "
                                        :state="
                                            validateState('valor_ipoconsumo')
                                        "
                                        :disabled="read_only"
                                    />
                                    <div
                                        class="length-field mt-1"
                                        v-if="
                                            !$v.item.valor_ipoconsumo.$anyError
                                        "
                                    >
                                        ({{
                                            $v.item.valor_ipoconsumo.$model.toString()
                                                .length
                                        }}
                                        /
                                        {{
                                            $v.item.valor_ipoconsumo.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            !$v.item.valor_ipoconsumo.maxLength
                                        "
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.item.valor_ipoconsumo.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </div>
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            !$v.item.valor_ipoconsumo.required
                                        "
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                                <b-form-group v-if="item.tipo_ipoconsumo == 0">
                                    <label for="valor_ipoconsumo"
                                        >Porcentaje ipoconsumo</label
                                    >
                                    <b-form-input
                                        id="valor_ipoconsumo"
                                        type="number"
                                        placeholder="Ej: 19"
                                        v-model="
                                            $v.item.valor_ipoconsumo.$model
                                        "
                                        :state="
                                            validateState('valor_ipoconsumo')
                                        "
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="
                                            !$v.item.valor_ipoconsumo.$anyError
                                        "
                                    >
                                        ({{
                                            $v.item.valor_ipoconsumo.$model
                                                ? $v.item.valor_ipoconsumo.$model.toString()
                                                      .length
                                                : '0'.length
                                        }}
                                        /
                                        {{
                                            $v.item.valor_ipoconsumo.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.item.valor_ipoconsumo.maxLength
                                        "
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.item.valor_ipoconsumo.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.item.valor_ipoconsumo.required
                                        "
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-4">
                            <b-col cols="12" md="4" lg="4" xl="4">
                                <b-form-group>
                                    <label for="iva_id"
                                        >Iva
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="iva_id"
                                        :items="ivas"
                                        open-on-focus
                                        item-text="descripcion"
                                        item-value="id"
                                        show-create-button
                                        :create-method="
                                            () => {
                                                $bvModal.show(
                                                    'create-tarifa-iva-modal'
                                                )
                                            }
                                        "
                                        :disabled="
                                            ivas.length === 0 || read_only
                                        "
                                        v-model="$v.item.iva_id.$model"
                                        :state="validateState('iva_id')"
                                    />
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="$v.item.iva_id.$error"
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4" lg="4" xl="4">
                                <b-form-group>
                                    <label for="retencion_id"
                                        >Retención
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="retencion_id"
                                        :items="retenciones"
                                        item-text="descripcion"
                                        item-value="id"
                                        open-on-focus
                                        :disabled="
                                            retenciones.length === 0 ||
                                            read_only
                                        "
                                        v-model="$v.item.retencion_id.$model"
                                        :state="validateState('retencion_id')"
                                        show-create-button
                                        :create-method="
                                            () => {
                                                $bvModal.show(
                                                    'create-retencion-modal'
                                                )
                                            }
                                        "
                                    />
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="$v.item.retencion_id.$error"
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4" lg="4" xl="4">
                                <b-form-group>
                                    <label for="concepto_contable_id"
                                        >Concepto contable
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="concepto_contable_id"
                                        :items="conceptosContables"
                                        item-text="descripcion"
                                        item-value="id"
                                        open-on-focus
                                        :disabled="
                                            conceptosContables.length === 0 ||
                                            read_only
                                        "
                                        v-model="
                                            $v.item.concepto_contable_id.$model
                                        "
                                        :state="
                                            validateState(
                                                'concepto_contable_id'
                                            )
                                        "
                                    />
                                    <!-- show-create-button -->
                                    <!-- :create-method="
                                            () => {
                                                $bvModal.show(
                                                    'create-retencion-modal'
                                                )
                                            }
                                        " -->
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            $v.item.concepto_contable_id.$error
                                        "
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                    <crearSubcategoriaModal
                        @newSubcategoria="setNewSubcategoria"
                    />
                </div>
            </b-card>
        </b-col>
        <b-col cols="12">
            <div class="float-right">
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        size="md"
                        class="mr-4"
                        v-b-popover.hover.top="'Presione esc'"
                        @click="back"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        class="d-flex align-items-center"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </div>
        </b-col>
        <crearTarifaIvaModalVue @newTarifaIva="setNewTarifaIva" />
        <crearRetefuenteModalVue @newRetencion="setNewRetencion" />
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import jautocomplete from '@/components/shared/autocomplete/jautocomplete'
import currencyInput from '@/components/shared/currency-input/currency-input'
import {
    addListenerCommands,
    removeListenerCommands,
    compareObjects,
    goToNextField
} from '@/utils/others'
import crearSubcategoriaModal from '../subcategoria/crearSubcategoriaModal.vue'
import crearTarifaIvaModalVue from '../tarifa-iva/crearTarifaIvaModal.vue'
import crearRetefuenteModalVue from '../retefuente/crearRetefuenteModal.vue'

const { API } = services

const defaultItem = () =>
    JSON.parse(
        JSON.stringify({
            descripcion: '',
            referencia: '',
            tipo_ipoconsumo: null,
            codigo_barra: '',
            contenido: 0,
            precio_base_venta: 0,
            valor_ipoconsumo: 0,
            subcategoria_id: 0,
            unidad_embalaje_id: 0,
            unidad_contenido_id: 0,
            iva_id: null,
            retencion_id: null,
            tipo_item_id: null,
            concepto_contable_id: 0,
            // retecree_id: 0,
            activo: true,
            empresa_id: null
        })
    )

export default {
    name: 'CrearItem',
    mixins: [validationMixin],
    components: {
        jautocomplete,
        currencyInput,
        crearSubcategoriaModal,
        crearTarifaIvaModalVue,
        crearRetefuenteModalVue
    },
    data: () => ({
        unidadesContenido: [],
        unidadesEmbalaje: [],
        subcategorias: [],
        retecrees: [],
        ivas: [],
        retenciones: [],
        tiposItem: [],
        conceptosContables: [],
        tiposIpoconsumo: [
            { id: '0', descripcion: 'Porcentaje' },
            { id: '1', descripcion: 'Valor' },
            { id: 'NA', descripcion: 'No apllica' }
        ],
        itemCopy: defaultItem(),
        item: defaultItem(),
        isLoading: false,
        isSaved: true,
        read_only: false
    }),
    watch: {
        item: {
            handler(newValue) {
                this.isSaved = compareObjects(this.itemCopy, newValue)
            },
            deep: true
        }
    },
    mounted() {
        // this.$bvModal.show('modal-crear-categoria')
        this.goToNextField('referencia')
        addListenerCommands(this)
        this.getParams()
        this.getConceptosContables()
        this.getUnidadesContenido()
        this.getUnidadesEmbalaje()
        this.getSubcategorias()
        this.getCategorias()
        this.getIvas()
        this.getRetenciones()
        this.getRetecrees()
        this.getTiposItem()
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    beforeRouteLeave(to, from, next) {
        if (this.isSaved) {
            next()
        } else {
            this.$bvModal
                .msgBoxConfirm(
                    `
        Este formulario contiene información que no ha sido guardada,
        si continua con esta acción no se guardarán los cambios, desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        next()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    validations: {
        item: {
            referencia: {
                required,
                maxLength: maxLength(25)
            },
            codigo_barra: {
                required,
                maxLength: maxLength(50)
            },
            descripcion: {
                required,
                maxLength: maxLength(150)
            },
            unidad_contenido_id: {
                required
            },
            contenido: {
                required,
                maxLength: maxLength(15)
            },
            unidad_embalaje_id: {
                required
            },
            precio_base_venta: {
                required,
                maxLength: maxLength(30)
            },
            subcategoria_id: {
                required
            },
            concepto_contable_id: {
                required
            },
            tipo_ipoconsumo: {
                required
            },
            valor_ipoconsumo: {
                required,
                maxLength: (value, obj) => {
                    if (obj.tipo_ipoconsumo == 1) {
                        return maxLength(10)(value)
                    } else {
                        return maxLength(4)(value)
                    }
                }
            },
            iva_id: {
                required
            },
            retencion_id: {
                required
            },
            tipo_item_id: {
                required
            },
            activo: {}
        }
    },
    methods: {
        goToNextField,
        showLog(data) {
            console.log(data)
        },
        setNewSubcategoria(subcategoria) {
            this.subcategorias.push(subcategoria)
            this.item.subcategoria_id = subcategoria.id
        },
        setNewTarifaIva(tarifaIva) {
            this.ivas.push(tarifaIva)
            this.item.iva_id = tarifaIva.id
        },
        setNewRetencion(retencion) {
            this.retenciones.push(retencion)
            this.item.retencion_id = retencion.id
        },
        getParams() {
            const { item_id } = this.$route.params
            if (item_id) {
                this.getItemById(item_id)
            } else {
                this.item.tipo_ipoconsumo = 'NA'
            }

            this.read_only = this.$route.meta.read_only
        },
        getItemById(id) {
            // console.log('acá 2');
            API.POST({
                url: 'administracion/inventario/item/query',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.itemCopy = { ...dato }
                        this.item = { ...dato }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getConceptosContables() {
            API.POST({
                url: 'administracion/concepto-digitacion/all',
                data: {
                    empresaId: this.getUltimaEmpresa.id
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.conceptosContables = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getTiposItem() {
            API.POST({
                url: 'administracion/tipo-item/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.tiposItem = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getUnidadesContenido() {
            API.POST({
                url: 'administracion/inventario/unidad-contenido/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.unidadesContenido = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getUnidadesEmbalaje() {
            API.POST({
                url: 'administracion/inventario/unidad-embalaje/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.unidadesEmbalaje = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getSubcategorias() {
            API.POST({
                url: 'administracion/inventario/subcategoria/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.subcategorias = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getCategorias() {
            API.POST({
                url: 'administracion/inventario/categoria/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.categorias = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getIvas() {
            API.POST({
                url: 'administracion/inventario/iva/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.ivas = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getRetenciones() {
            API.POST({
                url: 'administracion/inventario/autoretencion/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.retenciones = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getRetecrees() {
            API.POST({
                url: 'administracion/inventario/autoretencion/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.retecrees = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        save() {
            const valid = !this.$v.item.$invalid
            if (valid) {
                this.isLoading = true
                const p_opc = this.item.id ? 'UPDATE' : 'INSERT'
                this.item.empresa_id = this.getUltimaEmpresa.id
                API.POST({
                    url: 'administracion/inventario/item/crud',
                    data: {
                        p_datajson: {
                            ...this.item
                        },
                        p_opc,
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            this.isSaved = true
                            this.back()
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.item.$touch()
            }
        },
        back() {
            this.$router.back()
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.item[key]
            return $dirty ? !$error : null
        }
    },
    computed: {
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'command+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'ctrl+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back
                }
            } else {
                return {}
            }
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 15 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson']),
        ...mapGetters('empresas', ['getUltimaEmpresa'])
    }
}
</script>

<style></style>
